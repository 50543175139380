import * as types from './action-types';

export const closeSnackbar = () => ({ type: types.CLOSE_SNACKBAR });
export const openSnackbar = (data) => ({
  type: types.OPEN_SNACKBAR,
  payload: data,
});

export const openErrSnackbar = (message) => ({
  type: types.OPEN_SNACKBAR,
  payload: { type: 'error', message },
});

export const openWarnSnackbar = (message) => ({
  type: types.OPEN_SNACKBAR,
  payload: { type: 'warning', message },
});
