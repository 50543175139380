import Client from 'services/api';
import * as types from './action-types';
import * as commonTypes from '../common/action-types';
import locale from 'localization';

export const getCompanies = (page, limit) => (dispatch) => {
  let params = {};
  if (page) params = { ...params, page };
  if (limit) params = { ...params, limit };
  dispatch({ type: types.GET_COMPANIES_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });

  Client.get('api/v1/companies', params)
    .then((res) => {
      dispatch({ type: types.GET_COMPANIES_SUCCESS, payload: res });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    })
    .catch((res) => {
      dispatch({ type: types.GET_COMPANIES_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    });
};
export const getCompany = (id) => (dispatch) => {
  dispatch({ type: types.GET_COMPANY_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });

  Client.get(`api/v1/companies/${id}`)
    .then((res) => {
      dispatch({ type: types.GET_COMPANY_SUCCESS, payload: res.company });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    })
    .catch((err) => {
      dispatch({ type: types.GET_COMPANY_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    });
};

export const createCompany = (
  name,
  domains,
  placeCount,
  apiUrl,
  inCloud,
  contactName,
  contactEmail,
  contactPhone,
  startLicenseDate,
  endLicenseDate,
) => (dispatch) => {
  dispatch({ type: types.CREATE_COMPANY_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });
  return Client.post('api/v1/companies', {
    name,
    domains,
    placeCount: parseInt(placeCount, 10),
    apiUrl,
    inCloud,
    contactName,
    contactEmail,
    contactPhone,
    startLicenseDate,
    endLicenseDate,
  })
    .then((res) => {
      dispatch({ type: types.CREATE_COMPANY_SUCCESS, payload: res });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'success', message: locale.created },
      });
      return res;
    })
    .catch((err) => {
      dispatch({ type: types.CREATE_COMPANY_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'error', message: err.data.message },
      });
      throw err;
    });
};

export const updateCompany = (
  _id,
  name,
  domains,
  placeCount,
  apiUrl,
  inCloud,
  contactName,
  contactEmail,
  contactPhone,
  startLicenseDate,
  endLicenseDate,
) => (dispatch) => {
  dispatch({ type: types.UPDATE_COMPANY_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });
  return Client.put(`api/v1/companies/${_id}`, {
    name,
    domains,
    placeCount: parseInt(placeCount, 10),
    apiUrl,
    inCloud,
    contactName,
    contactEmail,
    contactPhone,
    startLicenseDate,
    endLicenseDate,
  })
    .then((res) => {
      dispatch({ type: types.UPDATE_COMPANY_SUCCESS, payload: res });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'success', message: locale.updated },
      });
      return res;
    })
    .catch((res) => {
      dispatch({ type: types.UPDATE_COMPANY_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      dispatch({
        type: commonTypes.OPEN_SNACKBAR,
        payload: { type: 'error', message: res.data.message },
      });
      throw res;
    });
};

export const ban_company_action = (data) => (dispatch) => {
  dispatch({ type: types.BAN_ACTION_COMPANY_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });
  return Client.put(`api/v1/companies/${data._id}`, data)
    .then((res) => {
      dispatch({ type: types.BAN_ACTION_COMPANY_SUCCESS, payload: res });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    })
    .catch((err) => {
      dispatch({ type: types.BAN_ACTION_COMPANY_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
    });
};

export const deleteCompany = (id) => (dispatch) => {
  dispatch({ type: types.DELETE_COMPANY_REQUEST });
  dispatch({ type: commonTypes.SHOW_SPINNER });
  return Client.delete(`api/v1/companies/${id}`)
    .then((res) => {
      dispatch({ type: types.DELETE_COMPANY_SUCCESS, payload: id });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      return res;
    })
    .catch((err) => {
      dispatch({ type: types.DELETE_COMPANY_FAILURE });
      dispatch({ type: commonTypes.HIDE_SPINNER });
      throw err;
    });
};
