import React, { useState } from 'react';
import locale from 'localization';
import validator from 'validator';
import { connect } from 'react-redux';
import { confirmCode, requestCode } from 'redux/auth/actions';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Timer from 'components/common/Timer';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
  },
  avatar: {
    margin: theme.spacing(1),
  },
  avatarImage: {
    height: 60,
    width: 60,
  },
  form: {
    marginTop: theme.spacing(1),
    width: '300px',
  },
  submit: {
    marginTop: 16,
  },
  codeText: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 20px',
    width: '1440px',
  },
}));

const Login = ({ requestCode, confirmCode, autorized }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    email: '',
    emailValid: true,
    enterCode: false,
    code: '',
    codeMessage: false,
    reloadCode: false,
  });

  const handleEmailChange = (e) => {
    const value = e.target.value.trim();
    setState({
      ...state,
      email: value,
      emailValid: validator.isEmail(value),
    });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (validator.isEmail(state.email)) {
      requestCode(state.email)
        .then(() => {
          setState({
            ...state,
            enterCode: true,
          });
        })
        .catch(() => {});
    } else {
      setState({ ...state, emailMessage: true });
    }
  };

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    confirmCode({ email: state.email, code: state.code });
  };

  const handleCodeChange = (e) => {
    const value = e.target.value.trim();

    setState({ ...state, code: value });
  };

  const handleEnableReloadCode = (e) => {
    setState({ ...state, reloadCode: true });
  };

  const handleGetCodeAgain = (e) => {
    e.persist();
    requestCode(state.email)
      .then((res) => {
        setState({ ...state, reloadCode: false, enterCode: true });
      })
      .catch((res) => {});
  };

  return (
    <Container component="main" className={classes.mainWrapper}>
      <div className={classes.paper}>
        <img src="/logo.png" alt="no_image" className={classes.avatarImage} />

        <Typography component="h1" variant="h5">
          {locale.login_title}
        </Typography>
        {!state.enterCode ? (
          <form
            className={classes.form}
            onSubmit={handleEmailSubmit}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={state.email}
              onChange={handleEmailChange}
              error={!state.emailValid}
              helperText={!state.emailValid && locale.login_email_field_error}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {locale.login_button}
            </Button>
          </form>
        ) : (
          <form className={classes.form} onSubmit={handleCodeSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="code"
              label="Code"
              name="code"
              autoComplete="off"
              autoFocus
              value={state.code}
              onChange={handleCodeChange}
            />
            {!state.reloadCode ? (
              <Timer time={300000} callback={handleEnableReloadCode} />
            ) : (
              <Typography
                onClick={handleGetCodeAgain}
                className={classes.codeText}
              >
                {locale.login_refresh_code}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {locale.login_button}
            </Button>
          </form>
        )}
      </div>
      {autorized && <Redirect to="/" />}
    </Container>
  );
};

const mapStateToProps = (state) => ({ autorized: state.auth.autorized });

const mapDispatchToProps = { requestCode, confirmCode };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
