import React, { useState } from 'react';
import locale from '../../../../localization';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import validator from 'validator';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/ru';

moment.locale(locale.lang);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    border: 'none',
    outline: 'none',
  },
  container: {
    border: 'none',
    outline: 'none',
    maxWidth: 650,
  },
  paper: {
    padding: '0px 0px 20px 0px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    boxShadow: '0 0 10px rgba(80,80,80,0.4)',
    borderRadius: 10,
    position: 'relative',
  },
  button: {
    textTransform: 'none',
    height: 40,
    marginTop: 10,
  },
  title: {
    padding: theme.spacing(1) + 12,
    lineHeight: '24px',
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#FFF',
  },
  content: {
    padding: theme.spacing(1) + 12,
  },
  textField: {
    marginBottom: 10,
    height: 56,
  },
  dates_wrapper: {
    display: 'flex',
    '&>*': {
      width: 900,
    },
    '&>*:last-child': {
      marginLeft: 16,
    },
  },
  switcherWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  switcherLabel: {
    color: 'gray',
  },
}));

const Add = ({ open = true, onClose, createCompany }) => {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    name: '',
    nameValid: true,
    domains: '',
    domainsValid: true,
    placeCount: '',
    placeCountValid: true,
    apiUrl: '',
    apiUrlValid: true,
    inCloud: false,
    contactName: '',
    contactNameValid: true,
    contactEmail: '',
    contactPhone: '',
    contactPhoneValid: true,
    startLicenseDate: null,
    startLicenseDateValid: true,
    endLicenseDate: null,
    endLicenseDateValid: true,
  });

  const validateEmpty = (name) => {
    return !validator.isEmpty(name);
  };

  const validateDomain = (domain) => {
    const regexp = new RegExp(
      /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/,
    );
    return regexp.test(domain);
  };

  const validateplaceCount = (value) => {
    return validator.isInt(value, { min: 0 });
  };

  const validatePhoneNumber = (value) => {
    return validator.isMobilePhone(value || '');
  };

  const onNameChange = (e) => {
    const { value } = e.target;
    setState({
      ...state,
      nameValid: validateEmpty(value),
      name: value,
    });
  };

  const onDomainChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      domainsValid: value.split(',').every((d) => validateDomain(d.trim())),
      domains: value,
    });
  };
  const oninCloudChange = (e) => {
    setState({ ...state, inCloud: e.target.checked });
  };

  const onplaceCountChange = (e) => {
    const { value } = e.target;
    setState({
      ...state,
      placeCountValid: validateplaceCount(value, state.domains),
      placeCount: value,
    });
  };

  const onApiURLChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      apiUrlValid: validateEmpty(value),
      apiUrl: value,
    });
  };
  const onContactNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      contactNameValid: validateEmpty(value),
      contactName: value,
    });
  };
  const onContactPhoneChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      contactPhoneValid: validateEmpty(value),
      contactPhone: value,
    });
  };

  const onStartLicenseDateChange = (value) => {
    setState({
      ...state,
      startLicenseDate: value ? new Date(value) : value,
      startLicenseDateValid: !!value,
    });
  };

  const onEndLicenseDateChange = (value) => {
    setState({
      ...state,
      endLicenseDate: value ? new Date(value) : value,
      endLicenseDateValid: !!value,
    });
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    setState({
      ...state,
      contactEmail: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!validateEmpty(state.name)) {
      setState({ ...state, nameValid: false });
    } else if (state.domains.split(',').every((d) => !validateDomain(d.trim())))
      setState({ ...state, domainsValid: false });
    else if (!validateplaceCount(state.placeCount))
      setState({ ...state, placeCountValid: false });
    else if (!validateEmpty(state.apiUrl))
      setState({ ...state, apiUrlValid: false });
    else if (!validateEmpty(state.contactName))
      setState({ ...state, contactNameValid: false });
    else if (!validatePhoneNumber(state.contactPhone))
      setState({ ...state, contactPhoneValid: false });
    else if (
      !state.startLicenseDate ||
      state.startLicenseDate === 'Invalid date'
    )
      setState({ ...state, startLicenseDateValid: false });
    else if (!state.endLicenseDate || state.endLicenseDate === 'Invalid date') {
      setState({ ...state, endLicenseDateValid: false });
    } else {
      setDisabled(true);
      createCompany(
        state.name,
        state.domains.split(',').map((d) => d.trim()),
        state.placeCount,
        state.apiUrl,
        state.inCloud,
        state.contactName,
        state.contactEmail,
        state.contactPhone,
        new Date(state.startLicenseDate).toISOString(),
        new Date(state.endLicenseDate).toISOString(),
      )
        .then((res) => {
          handleClose();
          setDisabled(false);
        })
        .catch((err) => {
          if (err.data && err.data.fields && err.data.fields.length) {
            let newState = { ...state };
            err.data.fields.forEach((field) => {
              newState[`${field}Valid`] = false;
            });
            setState(newState);
          }
          setDisabled(false);
        });
    }
  };

  const handleClose = () => {
    setState({
      name: '',
      nameValid: true,
      domains: '',
      domainsValid: true,
      placeCount: '',
      placeCountValid: true,
      apiUrl: '',
      apiUrlValid: true,
      inCloud: false,
      contactName: '',
      contactNameValid: true,
      contactEmail: '',
      contactPhone: '',
      contactPhoneValid: true,
      startLicenseDate: null,
      startLicenseDateValid: true,
      endLicenseDate: null,
      endLicenseDateValid: true,
    });
    onClose();
  };
  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.title} align="center">
            {locale.create_company_title}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ) : null}
          <div className={classes.content}>
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_name_field_label}
              fullWidth
              value={state.name}
              onChange={onNameChange}
              error={!state.nameValid}
              required
              helperText={!state.nameValid && locale.company_name_field_error}
            />
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_domain_field_label}
              fullWidth
              value={state.domains}
              onChange={onDomainChange}
              error={!state.domainsValid}
              required
              helperText={
                !state.domainsValid && locale.company_domain_field_error
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_place_limit_field_label}
              fullWidth
              value={state.placeCount}
              onChange={onplaceCountChange}
              error={!state.placeCountValid}
              required
              helperText={
                !state.placeCountValid && locale.company_place_limit_field_error
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_place_url_field_label}
              fullWidth
              value={state.apiUrl}
              onChange={onApiURLChange}
              error={!state.apiUrlValid}
              required
              helperText={
                !state.placeCountValid && locale.company_place_limit_field_error
              }
            />
            <div className={classes.switcherWrapper}>
              <Typography className={classes.switcherLabel}>
                {locale.company_isCloud_switcher_label}
              </Typography>
              <Switch
                checked={state.inCloud}
                onChange={oninCloudChange}
                color="primary"
              />
            </div>

            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_place_contact_name_field_label}
              fullWidth
              value={state.contactName}
              onChange={onContactNameChange}
              error={!state.contactNameValid}
              required
              helperText={
                !state.contactNameValid &&
                locale.company_contactname_field_error
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_email_field_label}
              fullWidth
              value={state.contactEmail}
              onChange={onEmailChange}
              required
            />
            <TextField
              variant="outlined"
              margin="dense"
              className={classes.textField}
              label={locale.company_place_contact_number_field_label}
              fullWidth
              value={state.contactPhone}
              onChange={onContactPhoneChange}
              error={!state.contactPhoneValid}
              required
              helperText={
                !state.contactPhoneValid &&
                locale.company_contactphone_field_error
              }
            />
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale.lang}>
              <div className={classes.dates_wrapper}>
                <KeyboardDatePicker
                  required
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="DD.MM.YYYY"
                  margin="dense"
                  label={locale.company_activate_license_date}
                  value={state.startLicenseDate}
                  className={classes.dateField}
                  onChange={onStartLicenseDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={!state.startLicenseDateValid}
                  invalidDateMessage={locale.invaliDateLabel}
                  clearable
                  minDateMessage={locale.min_date_message}
                  maxDateMessage={locale.max_date_message}
                />
                <KeyboardDatePicker
                  required
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="DD.MM.YYYY"
                  margin="dense"
                  label={locale.company_end_license_date}
                  value={state.endLicenseDate}
                  className={classes.dateField}
                  onChange={onEndLicenseDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={!state.endLicenseDateValid}
                  invalidDateMessage={locale.invaliDateLabel}
                  minDateMessage={locale.min_date_message}
                  maxDateMessage={locale.max_date_message}
                />
              </div>
            </MuiPickersUtilsProvider>
            <Button
              onClick={onSubmit}
              fullWidth
              disabled={disabled}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {locale.company_create_button_label}
            </Button>
          </div>
        </Paper>
      </Container>
    </Modal>
  );
};

export default Add;
