import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
};

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = { time: props.time };
  }

  intervalId = null;

  tick = () => {
    this.setState((state) => ({
      time: state.time > 0 ? state.time - 1000 : 0,
    }));
  };

  componentDidMount() {
    this.intervalId = setInterval(this.tick, 1000);
  }

  convert = (value) => {
    const minutes = Math.floor(value / 1000 / 60);
    let seconds = Math.round(value / 1000) - minutes * 60;
    if (('' + seconds).length < 2) seconds = '0' + seconds;
    return `${minutes}:${seconds}`;
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    if (this.state.time <= 0) {
      clearInterval(this.intervalId);
      this.props.callback();
    }
    return (
      <div className={this.props.classes.container}>
        <Typography>{this.convert(this.state.time)}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Timer);
