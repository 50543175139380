import React, { useEffect } from 'react';
import locale from '../../../localization';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { getCompany } from '../../../redux/companies/actions';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '32px 20px',
    width: '1440px',
  },
  wrapper: {
    padding: 32,
  },
  field: {
    display: 'flex',
  },
}));

const Company = ({ company, getCompany }) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    getCompany(params.id);
  }, []);

  return (
    <Container className={classes.container}>
      <Paper className={classes.wrapper}>
        <Grid container spacing="2">
          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_name_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.name}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_domain_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.domains ? company.domains.join(', ') : ''}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_created_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {new Date(company.createdAt).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_place_limit_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.placeCount}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_place_url_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.apiUrl}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_place_cloud_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.inCloud ? locale.yes : locale.no}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_place_contact_name_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.contactName}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_place_contact_number_field_label}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.contactPhone}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_places_count}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.realPlaceCount}
            </Typography>
          </Grid>

          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_last_check_date}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.dateOfCheck
                ? new Date(company.dateOfCheck).toLocaleDateString()
                : locale.empty_field}
            </Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_activate_license_date}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.startLicenseDate
                ? new Date(company.startLicenseDate).toLocaleDateString()
                : locale.empty_field}
            </Typography>
          </Grid>
          <Grid item xs="3">
            <Typography variant="h6" align="left">
              {locale.company_end_license_date}:
            </Typography>
          </Grid>
          <Grid item xs="8">
            <Typography variant="h6" align="left">
              {company.endLicenseDate
                ? new Date(company.endLicenseDate).toLocaleDateString()
                : locale.empty_field}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  company: state.companies.company,
});

const mapDispatchToProps = {
  getCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
