import ApiError from './apiError';
import locale from '../localization';

const apiUrl = process.env.REACT_APP_API_URL;

class ApiClient {
  constructor() {
    this.prefix = apiUrl;
    this._token =
      typeof localStorage.getItem('__AUTH__TOKEN__') === 'string'
        ? localStorage.getItem('__AUTH__TOKEN__')
        : '';
    this.refreshToken =
      typeof localStorage.getItem('__AUTH__REFRESH__TOKEN__') === 'string'
        ? localStorage.getItem('__AUTH__REFRESH__TOKEN__')
        : '';
  }

  setToken = (token, refreshToken) => {
    localStorage.setItem('__AUTH__TOKEN__', token);
    localStorage.setItem('__AUTH__REFRESH__TOKEN__', refreshToken);
    this._token = token;
  };

  deleteToken = () => {
    localStorage.removeItem('__AUTH__TOKEN__');
    localStorage.removeItem('__AUTH__REFRESH__TOKEN__');
  };

  getToken = () => {
    return {
      token: localStorage.getItem('__AUTH__TOKEN__'),
      refreshToken: localStorage.getItem('__AUTH__REFRESH__TOKEN__'),
    };
  };

  get(requestUrl, query = {}) {
    return this.request({
      url: requestUrl,
      method: 'GET',
      query,
    });
  }

  put(requestUrl, payload = {}) {
    return this.request({
      url: requestUrl,
      method: 'PUT',
      body: payload,
    });
  }

  patch(requestUrl, payload = {}) {
    return this.request({
      url: requestUrl,
      method: 'PATCH',
      body: payload,
    });
  }

  post(requestUrl, payload = {}, contentType) {
    return this.request({
      url: requestUrl,
      method: 'POST',
      body: payload,
      contentType,
    });
  }

  delete(requestUrl, payload = {}) {
    return this.request({
      url: requestUrl,
      method: 'DELETE',
      body: payload,
    });
  }

  request({ url, method, body, contentType, query }) {
    let status,
      headers,
      init,
      queryString = '';

    init = {
      method,
      headers: {
        Authorization: this._token ? `Bearer ${this._token}` : '',
        Accept: 'application/json',
        'Accept-Language': locale.lang,
      },
    };

    if (method !== 'GET') {
      init = {
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': contentType || 'application/json',
        },
      };
    }

    if (typeof query === 'object' && Object.keys(query).length) {
      queryString = '?';
      for (let key in query) {
        if (Array.isArray(query[key])) {
          queryString += query[key].reduce(
            (accum, item) => (accum += `${key}=${item}&`),
            '',
          );
        } else queryString += `${key}=${query[key]}&`;
      }
      queryString = queryString.substr(0, queryString.length - 1);
    }

    if (method !== 'GET' && method !== 'HEAD') {
      init.body = JSON.stringify(body);
    }

    return fetch(`${this.prefix}/${url}${queryString}`, init)
      .then((res) => {
        const { status, statusText } = res;

        if (200 <= status && status < 400) return res.json();
        if (status === 401) {
          this.deleteToken();
          window.location = '/login';
        }
        throw res;
      })
      .then((data) => {
        if (data) {
          return data;
        } else new Error('Something went wrong');
      })
      .catch(async (res) => {
        let a;
        try {
          a = await res.json();
        } catch (err) {}
        throw new ApiError({ status: res.status, data: a });
      });
  }
}

export default new ApiClient();
