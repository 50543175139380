import React, { useEffect, useState } from 'react';
import locale from '../../../localization';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddOutlined from '@material-ui/icons/AddOutlined';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  ban_company_action,
  createCompany,
  deleteCompany,
  getCompanies,
  updateCompany,
} from '../../../redux/companies/actions';
import { useHistory } from 'react-router-dom';
import Add from './Add';
import Edit from './Edit';

import Delete from 'components/common/Delete';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '32px 20px',
    maxWidth: '1440px',
  },
  headTab: {
    backgroundColor: '#EFEFF4',
  },
  title: {
    fontWeight: 500,
  },
  button: {
    textTransform: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
  },
  action_button: {
    textTransform: 'none',
  },
  actions_wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '320px',
  },
  table_column: {
    '&>div::before': {
      border: 'none !important',
    },
  },
  limited: { backgroundColor: 'rgba(245,0,87,0.2)' },
  expired: { backgroundColor: 'rgba(247,152,67,0.2)' },
  row: {
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#d3d3d3',

      '&>.company_name': {
        color: '#66ccff ',
        textDecoration: 'underline',
      },
    },
  },
}));

const Companies = ({
  companies,
  totalCount,
  getCompanies,
  createCompany,
  updateCompany,
  ban_company_action,
  deleteCompany,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addModalShow, switchAddModal] = useState(false);
  const [editModalParams, setEditModalParams] = useState({
    open: false,
    data: { _id: '', domain: '', name: '' },
  });
  const [deleteModalParams, setDeleteModalParams] = useState({
    open: false,
    data: null,
  });

  const handleDeleteCompany = (id) => (e) => {
    e.stopPropagation();
    setDeleteModalParams({ open: true, data: id });
  };

  const hideDeleteModal = () => {
    setDeleteModalParams({ open: false, data: null });
  };

  const handleAddCompany = (...rest) => {
    return createCompany(...rest)
      .then((res) => {
        getCompanies(page + 1, rowsPerPage);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const history = useHistory();

  useEffect(() => {
    getCompanies(page + 1, rowsPerPage);
  }, [getCompanies, page, rowsPerPage]);

  const setCurrentPage = (e, page) => {
    setPage(page);
  };

  const setCurrentRowsPerPage = (e) => {
    setPage(0);
    setRowsPerPage(e.target.value);
  };

  const hideAddModal = () => {
    switchAddModal(false);
  };
  const showAddModal = (e) => {
    switchAddModal(true);
  };

  const showEditModal = (data) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditModalParams({ ...editModalParams, open: true, data });
  };

  const hideEditModal = () => {
    setEditModalParams({
      ...editModalParams,
      open: false,
      data: { _id: '', domain: '', name: '' },
    });
  };
  const handleCompanyBan = (companyId) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    ban_company_action(companyId).then().catch();
  };
  const onCompanyClick = (id) => (e) => {
    history.push(`/companies/${id}`);
  };
  return (
    <Container className={classes.container}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h5" align="left" className={classes.title}>
            {locale.companies_title}
          </Typography>
        </Grid>
        <Grid item>
          <Button className={classes.button} onClick={showAddModal}>
            <AddOutlined fontSize="small" />
            {locale.create_company_title}
          </Button>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>{locale.company_name_field_label}</Typography>
            </TableCell>
            {
              /* <TableCell>
              <Typography>{locale.company_domain_field_label}</Typography>
            </TableCell>
*/
              <TableCell>
                <Typography>
                  {locale.company_place_limit_field_label}
                </Typography>
              </TableCell>
              /* <TableCell>
              <Typography>{locale.company_place_url_field_label}</Typography>
            </TableCell> */
            }
            {/* <TableCell>
              <Typography>{locale.company_place_cloud_field_label}</Typography>
            </TableCell> */}
            {/* <TableCell>
              <Typography>
                {locale.company_place_contact_name_field_label}
              </Typography>
            </TableCell> */}
            {/* <TableCell>
              <Typography>
                {locale.company_place_contact_number_field_label}
              </Typography>
            </TableCell> */}
            {/* <TableCell>
              <Typography>{locale.company_license_label}</Typography>
            </TableCell> */}

            <TableCell>
              <Typography>{locale.company_activate_license_date}</Typography>
            </TableCell>

            <TableCell>
              <Typography>{locale.company_end_license_date}</Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((item) => (
            <TableRow
              key={item._id}
              className={classnames(
                classes.row,
                item.dateOfCheck &&
                  new Date() - new Date(item.dateOfCheck) > 604800000 &&
                  classes.expired,
                item.realPlaceCount > item.placeCount && classes.limited,
              )}
              onClick={onCompanyClick(item._id)}
            >
              <TableCell className="company_name">
                <Typography>{item.name}</Typography>
              </TableCell>
              {/* <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.domain}
                />
              </TableCell> */}
              <TableCell>
                <Typography>{item.placeCount}</Typography>
              </TableCell>
              {/* <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.apiUrl}
                />
              </TableCell> */}
              {/* <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.inCloud ? 'Да' : 'Нет'}
                />
              </TableCell> */}
              {/* <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.contactName}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.contactPhone}
                />
              </TableCell> */}
              {/* <TableCell>
                <TextField
                  className={classes.table_column}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={item.licenceKey}
                />
              </TableCell> */}
              <TableCell>
                <Typography>
                  {item.startLicenseDate
                    ? new Date(item.startLicenseDate).toLocaleDateString()
                    : locale.empty_field}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {item.endLicenseDate
                    ? new Date(item.endLicenseDate).toLocaleDateString()
                    : locale.empty_field}
                </Typography>
              </TableCell>

              <TableCell>
                <div className={classes.actions_wrapper}>
                  <Button
                    color="primary"
                    onClick={showEditModal(item)}
                    className={classes.action_button}
                  >
                    {locale.company_edit_button_label}
                  </Button>
                  {item.isBlocked ? (
                    <Button
                      color="secondary"
                      onClick={handleCompanyBan({ ...item, isBlocked: false })}
                      className={classes.action_button}
                    >
                      {locale.company_unban_button_label}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleCompanyBan({ ...item, isBlocked: true })}
                      className={classes.action_button}
                    >
                      {locale.company_ban_button_label}
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={handleDeleteCompany(item._id)}
                    className={classes.action_button}
                  >
                    {locale.delete_button_label}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={locale.company_rows_per_page}
        labelDisplayedRows={locale.company_displayed_rows}
        onChangePage={setCurrentPage}
        onChangeRowsPerPage={setCurrentRowsPerPage}
      />
      <Add
        open={addModalShow}
        onClose={hideAddModal}
        createCompany={handleAddCompany}
      />
      <Edit
        open={editModalParams.open}
        onClose={hideEditModal}
        data={editModalParams.data}
        updateCompany={updateCompany}
      />
      <Delete
        open={deleteModalParams.open}
        onClose={hideDeleteModal}
        deleteCallbackParams={deleteModalParams.data}
        onDelete={deleteCompany}
        title={locale.delete_company_title}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  companies: state.companies.companies,
  page: state.companies.page,
  pageCount: state.companies.pageCount,
  totalCount: state.companies.totalCount,
});

const mapDispatchToProps = {
  getCompanies,
  createCompany,
  updateCompany,
  ban_company_action,
  deleteCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
